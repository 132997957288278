import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ResourceListing from '../components/ResourceListing';
import ModularBlocks from '../components/ModularBlocks';
import { getCollectionLocaleFields } from '../utils';

const CaseStudiesArchiveTemplate = ({
  data: { datoCmsCaseStudiesArchive, allDatoCmsCaseStudy },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slug, slugLocales, title, banner, modularBlocks } =
    datoCmsCaseStudiesArchive;
  const { nodes } = getCollectionLocaleFields(allDatoCmsCaseStudy, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsCaseStudiesArchive}
    >
      <main>
        <Banner
          heading={title}
          text={banner.text}
          link={banner.link}
          image={banner.image}
        />
        <ResourceListing
          heading={title}
          items={nodes}
          archiveSlug={slug}
          locale={locale}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const CaseStudiesArchiveTemplateQuery = graphql`
  query CaseStudiesArchiveTemplateQuery($id: String!) {
    datoCmsCaseStudiesArchive(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...ContentModularBlockFragment
      }
    }
    allDatoCmsCaseStudy(sort: { fields: position, order: ASC }) {
      group(field: locale) {
        fieldValue
        nodes {
          ...CaseStudyResourceCardFragment
        }
      }
    }
  }
`;

export default CaseStudiesArchiveTemplate;
